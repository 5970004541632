import React from 'react';
import style from './landing-video.module.scss';
import { useTranslation } from 'react-i18next';
import { Carousel as CarouselElement } from 'react-responsive-carousel';
import { useMediaQuery } from '@material-ui/core';

export const LandingVideo = () => {
  const { t } = useTranslation();

  const matches = useMediaQuery('(max-width:600px)');
  const width = matches ? 300 : 800;
  const height = matches ? 187.5 : 450;

  return (
    <div className={style['landing-video']} id="video">
      <div>
        <h3>{t('landing_video_title')}</h3>
        <CarouselElement
          autoPlay={false}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          dynamicHeight={true}
          className={style['carousel-root']}
        >
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/hhZVgPY9cMY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/cYDU_lieZTU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/00IizvcoOTw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/wUNDkl7OR7s"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/YaVdrjwZNQU"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/9bGJ67wSAi0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div>
            <iframe
              width={width}
              height={height}
              src="https://www.youtube.com/embed/tBUyenLDYj0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </CarouselElement>
      </div>
    </div>
  );
};
