import React, { useEffect } from 'react';
import { EntryType, Visual } from '../ListEntry/ListEntry';
import { useTranslation } from 'react-i18next';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Loader } from '../Loader/Loader';
import style from './entry.module.scss';
import { CalendarToday } from '@material-ui/icons';
import { formatLandingDate, IsWindowScrolledConfig, slugify } from '../../utils';
import { Link, useLocation } from 'react-router-dom';
import { useScrollTrigger } from '@material-ui/core';

type Props = {
  type: EntryType;
  slug: string;
};

const useQuery = () => new URLSearchParams(useLocation().search);

export const Entry = ({ type, slug }: Props) => {
  const { t } = useTranslation();
  const queryFunc = useQuery();

  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  const args: {
    [key: string]: any;
  } = {
    method: 'GET',
  };

  args.slug = decodeURIComponent(slug);

  if (queryFunc.get('_preview')) {
    args._preview = queryFunc.get('_preview');
  }

  let url;
  let urlList;
  let title;
  let query;
  if (type === 'news') {
    url = 'Content/Cms/@news:loadSlug';
    urlList = 'Content/Cms/@news:search';
    title = t('landing_news_title');
    query = {
      sort: 'published:desc',
    };
  } else {
    url = 'Planner/@planner/Schedule:loadBySlug';
    urlList = 'Planner/@planner/Schedule';
    title = t('landing_live_title');
    query = {
      Visible: 'Y',
      sort: { Date: 'DESC' },
    };

    args.slug = decodeURIComponent(slug);
    args.paging = {
      // Optional, defined the paging of the request
      page_no: 0, // Which page we want
      results_per_page: 5, // How many results per page we want
    };
    args.query = query;
  }

  const [entry, refresh] = useRest<any>(url, args);

  const [entries, refreshEntries] = useRest<any>(urlList, args);

  let entryElement = (
    <>
      <Loader />
    </>
  );
  if (entry && entry.data) {
    const cms = entry.data.content_cms_entry_data; // changer a date du Schedule
    let date;
    if (type === 'news') {
      date = cms.Published || cms.Created;
    } else {
      date = entry.data.planner_schedule.Date;
    }

    let img = <></>;
    const imgUrl = cms.Top_Drive_Item ? cms.Top_Drive_Item.Media_Image.Url : '';
    if (imgUrl !== '') {
      img = <img src={imgUrl} className={style['main-img']} alt={'image'} />;
    }

    const data = {
      title: cms.Title,
      date: date.unix,
      content: cms.Contents,
    };

    entryElement = (
      <>
        <h3>{data.title}</h3>
        <div className={style['date']}>
          <CalendarToday />
          <span>{formatLandingDate(data.date)}</span>
        </div>
        <div className={style['content']}>
          {img}
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </>
    );
  }

  let list = <></>;
  if (entries) {
    let data;
    if (type === 'news') {
      data = entries.data.data.map(({ Title, Content_Cms__ }: any) => {
        return {
          path: `/news/${slugify(Title)}`,
          title: Title,
          id: Content_Cms__,
        };
      });
    } else {
      data = entries.data.map(({ Label, Planner_Schedule__ }: any) => {
        return {
          path: `/live/${slugify(Label)}`,
          title: Label,
          id: Planner_Schedule__,
        };
      });
    }
    const elms = data.map(({ id, path, title }: Visual, i: number) => {
      return (
        <div key={i} className={style['row']}>
          <Link to={path} className={style['link']}>
            {title}
          </Link>
        </div>
      );
    });
    list = (
      <div className={style['list-container']}>
        <h4>{title}</h4>
        {elms}
      </div>
    );
  }

  const tweeterUrl = `https://platform.twitter.com/widgets/tweet_button.06c6ee58c3810956b7509218508c7b56.ja.html#dnt=false&amp;id=twitter-widget-0&amp;lang=ja&amp;original_referer=${encodeURIComponent(
    location.href
  )}&amp;size=m&amp;text=HOMEY(%E3%83%9B%E3%83%BC%E3%83%9F%E3%83%BC)%20-%20Official%20Website&amp;time=1626735744967&amp;type=share&amp;url=${encodeURIComponent(
    location.href
  )}&amp;via=ONE_HOMEY`;

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['entry'], style['fixed-header']].join(' ') : style['entry'];

  return (
    <div className={classes}>
      <div>
        <h2>{title}</h2>
        <div className={style['container']}>
          {entryElement}
          <div className={style['sns']}>
            <iframe
              id="twitter-widget-0"
              scrolling="no"
              frameBorder="0" /*
              allowTransparency="true"
              allowFullScreen="true"*/
              className="twitter-share-button twitter-share-button-rendered twitter-tweet-button"
              style={{ position: 'static', visibility: 'visible', width: '74px', height: '28px' }}
              title="Twitter Tweet Button"
              src={tweeterUrl}
            />
            <div
              className="fb-like"
              data-href={location.href}
              data-width=""
              data-layout="button"
              data-action="like"
              data-size="small"
              data-share="false"
            />
          </div>
          {list}
        </div>
      </div>
    </div>
  );
};
