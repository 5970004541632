import React from 'react';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { LandingMail } from '../../components/Mail/LandingMail';
import { BgFixed } from '../../components/BgFixed/BgFixed';
import { Entry } from '../../components/Entry/Entry';
import { useParams } from 'react-router';

export const LiveEntry = () => {
  const { slug } = useParams<any>();

  return (
    <>
      <BgFixed />
      <Header />
      <ScrollTop />
      <Entry type={'schedule'} slug={slug} />
      <LandingMail />
      <Footer />
    </>
  );
};
