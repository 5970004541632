import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import style from './list-entry.module.scss';
import { formatLandingDate, IsWindowScrolledConfig } from '../../utils';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { Chip, useScrollTrigger } from '@material-ui/core';
import { DateTime, Interval } from 'luxon';

export type EntryType = 'news' | 'schedule';

type Props = {
  type: EntryType;
};

export type Visual = {
  id: string;
  path: string;
  date: number;
  title: string;
};

export const ListEntry = ({ type }: Props) => {
  const { t } = useTranslation();
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['list-entry'], style['fixed-header']].join(' ') : style['list-entry'];

  let url;
  let title;
  if (type === 'news') {
    url = 'Content/Cms/@news:search';
    title = t('landing_news_title');
  } else {
    url = 'Planner/@planner/Schedule';
    title = t('landing_live_title');
  }

  const [entries, refresh] = useRest<any>(url, {
    method: 'GET',
    paging: {
      // Optional, defined the paging of the request
      page_no: 0, // Which page we want
      results_per_page: 30, // How many results per page we want
    },
  });

  if (!entries || !entries.data) {
    return (
      <>
        <Loader />
      </>
    );
  }

  let data: Visual[];
  if (type === 'news') {
    data = entries.data.data.map(({ Created, Title, Content_Cms__, Slug }: any) => {
      return {
        path: `/news/${Slug}`,
        title: Title,
        date: Created.unix,
        id: Content_Cms__,
      };
    });
  } else {
    data = entries.data.map(({ Date, Label, Planner_Schedule__, Content_Cms_Entry_Data }: any) => {
      return {
        path: `/live/${Content_Cms_Entry_Data.Slug}`,
        title: Label,
        date: Date.unix,
        id: Planner_Schedule__,
      };
    });
  }

  const elements = data.map(({ date, id, path, title }: Visual, i: number) => {
    const isOneWeekOld = Interval.fromDateTimes(DateTime.fromSeconds(date), DateTime.now()).length('weeks') < 1;
    return (
      <div key={i} className={style['row']}>
        <div className={style['date']}>
          {isOneWeekOld && type === 'news' ? (
            <Chip size={'small'} className={style['new']} label={t('common_new')} />
          ) : (
            <></>
          )}
          {formatLandingDate(date)}
        </div>
        <Link to={path} className={style['link']}>
          {title}
        </Link>
      </div>
    );
  });

  return (
    <div className={classes}>
      <div>
        <h2>{title}</h2>
        <div className={style['container']}>{elements}</div>
      </div>
    </div>
  );
};
