import React from 'react';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { Footer } from '../../components/common/Footer';
import { OrderBody } from './Body/OrderBody';

type Props = {
  user: any;
  readonly?: boolean;
};

export const Order = ({ user, readonly }: Props) => {
  return (
    <>
      <Header />
      <ScrollTop />
      <OrderBody user={user} readonly={readonly} />
      <Footer />
    </>
  );
};
