import React from 'react';
import style from './act-body.module.scss';
import phoneNumber from './../../../assets/images/number.png';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

export const ActBody = () => {
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['policy'], style['fixed-header']].join(' ') : style['policy'];

  return (
    <div className={classes}>
      <div>
        <div className={style['text']}>
          <div id="law">
            <h2>Act on Specified Commercial Transactions</h2>
            <h3>Seller</h3>
            <p>西村佳大</p>
            <h3>Seller&apos;s Address</h3>
            <p>ZIP Code ：2130015</p>
            <p>Address ：Kanagawa川崎市高津区梶ケ谷2-1-8 クレスティークソフィア 101</p>
            <h3>Contact</h3>
            <p>
              Phone Number ：
              <img src={phoneNumber} alt="" />
            </p>
            <p>定休日 : 日曜日</p>
            <h3>About Price</h3>
            <p>販売価格は、表示された金額（表示価格/消費税込）と致します。</p>
            <h3>About Payment Billing Date</h3>
            <p>
              支払方法：クレジットカードによる決済がご利用頂けます。支払時期：商品注文確定時でお支払いが確定致します。
            </p>
            <h3>Shipping Date</h3>
            <p>配送のご依頼を受けてから5日以内に発送いたします。</p>
            <h3>Notes on Refund and Returning Goods</h3>
            <p>商品に欠陥がある場合を除き、基本的には返品には応じません。</p>
          </div>
        </div>
      </div>
    </div>
  );
};
