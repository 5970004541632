import React, { useContext, useEffect, useState } from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { getLocale, rest } from '@karpeleslab/klbfw';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CartElement } from '../../../components/Cart/CartElement';
import { Countries } from '../../../components/Countries/Countries';
import style from './checkout-body.module.scss';
import { Button, TextField, useScrollTrigger } from '@material-ui/core';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { PageLoaderContext } from '../../../contexts/PageLoaderContext';
import { IsWindowScrolledConfig } from '../../../utils';

type Props = {
  user: any;
};

export const CheckoutBody = ({ user }: Props) => {
  const [cart] = useRest('Catalog/Cart/@');
  const [country] = useState(getLocale().split('-')[1]);
  const [currentCountry] = useRest<any>('ThirdParty/Geoip:lookup');
  const { t } = useTranslation();
  const history = useHistory();
  const setSnack = useContext(SnackbarContext);
  const setPageLoader = useContext(PageLoaderContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (currentCountry !== null) {
      setFormVal({
        ...formVal,
        Country__: currentCountry.data.country.iso_code,
      });
    }
  }, [currentCountry]);

  const [formVal, setFormVal] = useState<any>({
    Nickname: '',
    First_Name: '',
    Last_Name: '',
    Zip: '',
    Country__: 'JP',
    Address: '',
    Address2: '',
    City: '',
    Province: '',
    Contact_Phone: '',
  });

  const [formSort] = useState({
    JP: [
      [
        {
          name: 'Nickname',
          required: true,
        },
      ],
      [
        {
          name: 'Last_Name',
          required: true,
        },
        {
          name: 'First_Name',
          required: true,
        },
      ],
      [
        {
          name: 'Zip',
          required: true,
        },
        {
          name: 'Province',
          required: true,
        },
      ],
      [
        {
          name: 'City',
          required: true,
        },
        {
          name: 'Address',
          required: true,
        },
      ],
      [
        {
          name: 'Address2',
          required: false,
        },
        {
          name: 'Contact_Phone',
          required: true,
        },
      ],
    ],
    other: [
      [
        {
          name: 'Nickname',
          required: true,
        },
      ],
      [
        {
          name: 'First_Name',
          required: true,
        },
        {
          name: 'Last_Name',
          required: true,
        },
      ],
      [
        {
          name: 'Contact_Phone',
          required: true,
        },
        {
          name: 'Address',
          required: true,
        },
      ],
      [
        {
          name: 'Address2',
          required: false,
        },
        {
          name: 'City',
          required: true,
        },
      ],
      [
        {
          name: 'Province',
          required: true,
        },
        {
          name: 'Zip',
          required: true,
        },
      ],
    ],
  });

  const valChange = (event: any) => {
    const array: any = {};
    array[event.target.getAttribute('name')] = event.target.value;
    setFormVal({
      ...formVal,
      ...array,
    });
  };

  const buildRequired = (required: boolean) => {
    if (required) {
      return <span className={style['required']}>*</span>;
    } else {
      return null;
    }
  };

  const buildFromItem = (item: any) => {
    return item.map((rowItem: any, idx: number) => {
      if (rowItem.name === 'Country__') {
        return (
          <Countries
            key={idx}
            valChange={valChange}
            lang={formVal.Country__}
            rowItem={rowItem}
            item={item}
            buildRequired={buildRequired}
          />
        );
      } else {
        return (
          <dl key={idx} className={style['list-item']}>
            <dt>
              {t(`${rowItem.name}_lbl`)}
              {buildRequired(rowItem.required)}
            </dt>
            <dd>
              <TextField
                className={style['input']}
                onChange={valChange}
                type="text"
                value={formVal[rowItem.name]}
                name={rowItem.name}
                variant={'outlined'}
                fullWidth
              />
            </dd>
          </dl>
        );
      }
    });
  };

  const buildForm = () => {
    if (currentCountry === null || country === null) return false;

    let inputSort = formSort['JP'];

    if (country !== 'JP') {
      inputSort = formSort['other'];
    }

    return (
      <div className={style['bill-address']}>
        {inputSort.map((item, idx) => {
          return (
            <div className={style['list']} key={idx}>
              {buildFromItem(item)}
            </div>
          );
        })}
      </div>
    );
  };

  const sendForm = () => {
    setLoading(true);

    rest('User/@/Location', 'POST', formVal)
      .then((data: any) => {
        return rest('User/@:setDefaultLocation', 'POST', {
          User_Location__: data.data.User_Location__,
          type: 'Billing',
        });
      })
      .then((data: any) => {
        return rest('Catalog/Cart/@:createOrder', 'POST');
      })
      .then((data: any) => {
        setLoading(false);
        history.push('/order/' + data.data.Order__);
      })
      .catch((err: any) => {
        setLoading(false);
        setSnack(err.message, 'error');
      });
  };

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['checkout-body'], style['fixed-header']].join(' ') : style['checkout-body'];

  return (
    <div className={classes}>
      <div>
        <h3>{t('checkout_title')}</h3>
        <CartElement cart={cart} canRemove={false} />
        <h3>{t('checkout_bill_title')}</h3>
        {buildForm()}
        <div className={style['btn-container']}>
          <Button onClick={sendForm} disabled={loading} className={style['btn']}>
            {t('checkout_btn_payment')}
          </Button>
        </div>
      </div>
    </div>
  );
};
