import React, { useState } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import style from './shop-items.module.scss';
import missingImg from './../../../assets/images/logo.jpeg';
import { generatePath, Link } from 'react-router-dom';
import { shopEntryPageRoute } from '../../../const/routes';
import { AddToCard } from '../../../components/AddtoCard/AddToCard';

export const ShopItems = () => {
  const imgKey = 'format=jpeg&scale_crop=150x150&strip';

  const [products] = useRest<any>('Catalog/Product:search', {
    sort: 'Basic.Priority:desc',
    results_per_page: 50,
    Currency__: 'JPY',
    image_variation: [imgKey],
  });

  const buildPrice = (item: any) => {
    if (item['Price.Discount'] === 'Y') {
      if (item['Public_Price'] !== undefined) {
        return (
          <div className={style['price']}>
            <span style={{ textDecoration: 'line-through' }}>{item.Public_Price.tax.display}</span>
            {item.Price.tax.display}
          </div>
        );
      }
    }
    return <div className={style['price']}>{item.Price.tax.display}</div>;
  };

  let itemsElement = [
    <div key={'none'}>
      <Loader />
    </div>,
  ];
  if (products && products.data) {
    itemsElement = products.data.data.map((item: any) => {
      const title = item['Basic.Name'];
      const img = item.Image.main ? item.Image.main[0][imgKey] : missingImg;

      return (
        <div key={item.Catalog_Product__} className={style['item']}>
          <Link to={generatePath(shopEntryPageRoute, { id: item.Catalog_Product__ })}>
            <img src={img} alt={title} />
            <div className={style['title']}>{title}</div>
          </Link>
          {buildPrice(item)}
          <AddToCard id={item.Catalog_Product__} />
        </div>
      );
    });
  }
  return <div className={style['shop-items']}>{itemsElement}</div>;
};
