import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import style from './landing-news.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatLandingDate } from '../../../utils';
import { Loader } from '../../../components/Loader/Loader';
import { DateTime, Interval } from 'luxon';
import { Chip } from '@material-ui/core';

export const LandingNews = () => {
  const { t } = useTranslation();
  const [news, refresh] = useRest<any>('Content/Cms/@news:search', {
    method: 'GET',
    paging: {
      // Optional, defined the paging of the request
      page_no: 0, // Which page we want
      results_per_page: 5, // How many results per page we want
    },
    sort: 'published:desc',
  });

  const events =
    news && news.data && news.data.data ? (
      news.data.data.map(({ Published, Title, Content_Cms__, Slug }: any, i: number) => {
        const isOneWeekOld =
          Interval.fromDateTimes(DateTime.fromSeconds(Published.unix), DateTime.now()).length('weeks') < 1;
        return (
          <div key={i} className={style['row']}>
            <div className={style['date']}>
              {isOneWeekOld ? <Chip size={'small'} className={style['new']} label={t('common_new')} /> : <></>}
              {formatLandingDate(Published.unix)}
            </div>
            <Link to={`/news/${Slug}`} className={style['link']}>
              {Title}
            </Link>
          </div>
        );
      })
    ) : (
      <>
        <Loader />
      </>
    );

  return (
    <div className={style['landing-news']}>
      <div>
        <div className={style['mobile-backdrop']}>
          <h3>{t('landing_news_title')}</h3>
          {events}
        </div>
      </div>
    </div>
  );
};
