import React from 'react';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { LandingMail } from '../../components/Mail/LandingMail';
import { BgFixed } from '../../components/BgFixed/BgFixed';
import { ListEntry } from '../../components/ListEntry/ListEntry';

export const News = () => {
  return (
    <>
      <BgFixed />
      <Header />
      <ScrollTop />
      <ListEntry type={'news'} />
      <LandingMail />
      <Footer />
    </>
  );
};
