import React, { useContext } from 'react';
import style from './cart-element.module.scss';
import { Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { rest } from '@karpeleslab/klbfw';
import { useRestRefresh } from '@karpeleslab/react-klbfw-hooks';

type Props = {
  cart: any;
  canRemove: boolean;
};

export const CartElement = ({ cart, canRemove }: Props) => {
  const { t } = useTranslation();
  const setSnack = useContext(SnackbarContext);
  const cartRefresh = useRestRefresh('Catalog/Cart/@');

  const removeItem = (productId: string) => {
    rest('Catalog/Cart/@:setQuantity', 'POST', {
      key: productId,
      quantity: 0,
    })
      .then(() => {
        setSnack(t('toast_item_remove'), 'success');
        cartRefresh();
      })
      .catch(() => {
        setSnack(t('toast_item_remove_error'), 'error');
      });
  };

  let total = 0;
  let totalVatOnly = 0;
  let totalVat = 0;

  let cartElement = <></>;
  if (cart && cart.data) {
    let products = [];
    if (cart.data.products) {
      products = cart.data.products.map((product: any) => {
        return {
          key: product.key,
          name: product.data['Basic.Name'],
          quantity: product.quantity,
          price: product.price.tax.display,
        };
      });
      total = cart.data.total.display || 0;
      totalVatOnly = cart.data.total_vat_only.display || 0;
      totalVat = cart.data.total_vat.display || 0;
    } else if (cart.data.order) {
      products = cart.data.order.Items.map((product: any) => {
        return {
          key: product.Cart_Key,
          name: product.Catalog_Product['Basic.Name'],
          quantity: product.Quantity,
          price: product.Price_Vat.display,
        };
      });
      total = cart.data.order.Total.display || 0;
      totalVatOnly = cart.data.order.Vat_Amount.display || 0;
      totalVat = cart.data.order.Total_Vat.display || 0;
    }

    cartElement = (
      <TableContainer component={Paper}>
        <Table className={style['table']} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('cart_table_product')}</TableCell>
              <TableCell align="center">{t('cart_table_quantity')}</TableCell>
              <TableCell align="center">{t('cart_table_price')}</TableCell>
              {canRemove ? <TableCell align="center">{t('cart_table_remove')}</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product: any, i: number) => (
              <TableRow key={i} className={style['row']}>
                <TableCell component="th" scope="row">
                  {product.name}
                </TableCell>
                <TableCell align="center">{product.quantity}</TableCell>
                <TableCell align="right">{product.price}</TableCell>
                {canRemove ? (
                  <TableCell align="center">
                    <Fab className={style['btn']} size="small" onClick={() => removeItem(product.key)}>
                      <HighlightOff />
                    </Fab>
                  </TableCell>
                ) : (
                  <></>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  return (
    <div className={style['cart']}>
      {cartElement}
      <div className={style['total']}>
        <div className={style['total-box']}>
          <div>
            <span className={style['text']}>{t('cart_subtotal')}</span>
            <span className={style['value']}>{total}</span>
          </div>
          <div>
            <span className={style['text']}>{t('cart_tax')}</span>
            <span className={style['value']}>{totalVatOnly}</span>
          </div>
          <div>
            <span className={style['text']}>{t('cart_total')}</span>
            <span className={style['value']}>{totalVat}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
