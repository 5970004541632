import React from 'react';
import style from './landing-disco.module.scss';
import { Close } from '@material-ui/icons';
import album1 from './../../../assets/images/album1-modal.jpeg';
import album2 from './../../../assets/images/album2.jpeg';
import album3 from './../../../assets/images/album3.jpeg';
import album4 from './../../../assets/images/album4.jpeg';
import album5 from './../../../assets/images/album5.jpeg';
import album6 from './../../../assets/images/album6-modal.jpg';

type Props = {
  index: number;
  onClose: () => void;
};

export const DiscoModal = ({ index, onClose }: Props) => {
  const shop = `${location.origin}/shop`;

  const albums = [
    {
      img: album6,
      alt: 'Non Sugar',
      text: (
        <>
          <p>New Album [Non Sugar]</p>
          <p>Now On Sale</p>
          <p>&nbsp;</p>
          <p>
            01. Non Sugar -Intro-
            <br />
            02. One More Step
            <br />
            03. One Way Love
            <br />
            04. Kick Snare
            <br />
            05. Always
            <br />
            06. Flower
            <br />
            07. Kick Snare -Instrumental-
            <br />
            08. Always -Instrumental-
            <br />¥ 1,980 (tax included) Available at the
          </p>
          <p>&nbsp;</p>
          <p>
            live venue and online ( <a href={shop}>{shop}</a> ).
          </p>
        </>
      ),
    },
    {
      img: album1,
      alt: 'ATMOSPHERE',
      text: (
        <>
          <p>New Album [ATMOSPHERE]</p>
          <p>Now On Sale</p>
          <p>&nbsp;</p>
          <p>
            01. Atmosphere -Intro-
            <br />
            02. THE WAY
            <br />
            03. Having&apos;A Good Day
            <br />
            04. If you are
            <br />
            05. There is no eternity -Album Ver.-
            <br />
            06. KISS
            <br />
            07. On & On
            <br />
            08. Maybe Today
            <br />
            09. Through My Fingers
            <br />
            10. Dreamer
            <br />¥ 2,160 (tax included) Available at the
          </p>
          <p>&nbsp;</p>
          <p>
            live venue and online ( <a href={shop}>{shop}</a> ).
          </p>
        </>
      ),
    },
    {
      img: album2,
      alt: 'NICE N EASY',
      text: (
        <>
          <p>New Single [NICE N EASY]</p>
          <p>Now On Sale</p>
          <p>&nbsp;</p>
          <p>
            01. NICE N EASY
            <br />
            02. You Know Who You Are
            <br />
            03. missing you
            <br />
            04. You Are
            <br />
            <br />¥ 1,080 (tax included) Available at the
          </p>
          <p>&nbsp;</p>
          <p>
            live venue and online ( <a href={shop}>{shop}</a> ).
          </p>
        </>
      ),
    },
    {
      img: album3,
      alt: 'Be The One',
      text: (
        <>
          <p>Be The One * Sold out</p>
          <p>2010/12/29 TSRC-5</p>
          <p>&nbsp;</p>
          <p>
            01. Be The One
            <br />
            02. Yes I Do feat.SHIROSE
            <br />
            03. Fight Out
            <br />
            04. Fast Ride feat.Spice Twice
            <br />
            05. ONE N ONLY 8bit Remix
          </p>
          <p>&nbsp;</p>
          <p>* All 5 songs mero Chaku-Uta / Chaku-Uta Full, Charity is being delivered on .jp!</p>
        </>
      ),
    },
    {
      img: album4,
      alt: 'ONE IN A MILLION',
      text: (
        <>
          <p>ONE IN A MILLION</p>
          <p>2012/3/28 TSRC-17</p>
          <p>&nbsp;</p>
          <p>
            01. LMF
            <br />
            02. Leave It To Me
            <br />
            03. Rock The Party feat.MICKY RICH
            <br />
            04. ONE IN A MILLION
            <br />
            05. Break Down
            <br />
            06. More Than Enough
            <br />
            07. Lovin&apos;It feat.Grand Focus
          </p>
        </>
      ),
    },
    {
      img: album5,
      alt: '"ONE IN A MILLION" LIVE DVD',
      text: (
        <>
          <p>1st One-Man Live</p>
          <p>&quot;ONE IN A MILLION&quot; LIVE DVD</p>
          <p>2013/2/10</p>
          <p>Venue: Shibuya WWW</p>
          <p>All 25 songs recorded</p>
        </>
      ),
    },
  ];

  const data = albums[index];

  return (
    <div key={index} className={style['landing-disco-modal']}>
      <div>
        <div className={style['img']}>
          <img src={data.img} alt={data.alt} />
        </div>
        <div className={style['text']}>{data.text}</div>
        <div className={style['close']} onClick={onClose}>
          <Close />
        </div>
      </div>
    </div>
  );
};
