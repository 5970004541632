import React, { useState } from 'react';
import style from './landing-disco.module.scss';
import { useTranslation } from 'react-i18next';
import album1 from './../../../assets/images/album1.jpeg';
import album2 from './../../../assets/images/album2.jpeg';
import album3 from './../../../assets/images/album3.jpeg';
import album4 from './../../../assets/images/album4.jpeg';
import album5 from './../../../assets/images/album5.jpeg';
import album6 from './../../../assets/images/album6.jpg';
import { Modal } from '@material-ui/core';
import { DiscoModal } from './DiscoModal';

export const LandingDisco = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const openModal = (index: number) => {
    setOpen(true);
    setModalIndex(index);
  };

  const albums = [
    {
      img: album6,
      title: 'Non Sugar',
    },
    {
      img: album1,
      title: 'ATMOSPHERE',
    },
    {
      img: album2,
      title: 'NICE N EASY',
    },
    {
      img: album3,
      title: 'Be The One',
    },
    {
      img: album4,
      title: 'ONE IN A MILLION',
    },
    {
      img: album5,
      title: 'ONE IN A MILLION LIVE DVD',
    },
  ].map(({ img, title }, i) => {
    return (
      <div key={i} onClick={() => openModal(i)}>
        <img src={img} alt={title} />
        <h5>{title}</h5>
      </div>
    );
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={style['landing-disco']} id="disco">
      <div>
        <h3>{t('landing_disco_title')}</h3>
        <div className={style['disco']}>{albums}</div>
      </div>
      <Modal open={open} onClose={handleClose} BackdropProps={{ className: style['backdrop-yellow'] }}>
        <>
          <DiscoModal index={modalIndex} onClose={handleClose} />
        </>
      </Modal>
    </div>
  );
};
