import React from 'react';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { Footer } from '../../components/common/Footer';
import { AboutBody } from './Body/AboutBody';

export const About = () => {
  return (
    <>
      <Header />
      <ScrollTop />
      <AboutBody />
      <Footer />
    </>
  );
};
