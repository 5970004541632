import React, { useContext, useRef, useState } from 'react';
import style from './contact-body.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, FormControlLabel, Radio, RadioGroup, TextField, useScrollTrigger } from '@material-ui/core';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { rest, upload } from '@karpeleslab/klbfw';
import { IsWindowScrolledConfig } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { landingPageRoute } from '../../../const/routes';

type PropsInput = {
  title: string;
  required: boolean;
  big?: boolean;
  value: string;
  onChange: (value: string) => void;
};

const InputWithTitle = ({ title, required, big, value, onChange }: PropsInput) => {
  return (
    <div>
      <h4>
        {title}
        {required ? <span>*</span> : <></>}
      </h4>
      <TextField
        multiline
        variant={'outlined'}
        rows={big ? 5 : 1}
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

type PropsFileUpload = {
  title: string;
  required: boolean;
  onChange: (value: File) => void;
};

const InputWithFileUpload = ({ title, required, onChange }: PropsFileUpload) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [filename, setFilename] = useState('');

  const onFileChange = (event: any) => {
    if (!event.target.files) {
      return;
    }
    const file: File = event.target.files[0];
    setFilename(file.name);
    onChange(file);
  };

  const onClick = () => {
    inputRef.current?.click();
  };

  return (
    <div>
      <h4>
        {title}
        {required ? <span>*</span> : <></>}
      </h4>
      <input ref={inputRef} className={style['file-upload']} type="file" onChange={onFileChange} />
      <button onClick={onClick}>{t('common_upload')}</button>
      <p>{filename}</p>
    </div>
  );
};

type RadioOptions = {
  text: string;
  value: string;
};

type PropsRadio = {
  title: string;
  required: boolean;
  value: string;
  onChange: (value: string) => void;
  radios: RadioOptions[];
};

const RadioWithTitle = ({ title, required, value, onChange, radios }: PropsRadio) => {
  return (
    <div>
      <h4>
        {title}
        {required ? <span>*</span> : <></>}
      </h4>

      <RadioGroup row value={value} onChange={(e) => onChange(e.target.value)}>
        {radios.map(({ text, value }, i) => {
          return (
            <FormControlLabel
              key={i}
              value={value}
              control={<Radio color="primary" />}
              label={text}
              labelPlacement="end"
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export const ContactBody = () => {
  const { t } = useTranslation();
  const setSnack = useContext(SnackbarContext);
  const history = useHistory();

  const [requestType, setRequestType] = useState('');
  const [name, setName] = useState('');
  const [furiganaName, setFuriganaName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [furiganaCompanyName, setFuriganaCompanyName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [subject, setSubject] = useState('');
  const [feedback, setFeedback] = useState('');

  const send = () => {
    if (
      name.length === 0 ||
      mail.length === 0 ||
      requestType.length === 0 ||
      subject.length === 0 ||
      feedback.length === 0
    ) {
      setSnack(t('shop_contact_error_field'), 'error');
      return;
    }

    const query: any = {
      Email: mail,
      Name: name,
      To: '@support',
      Subject: `[Homey-web Support] Contact message from ${mail} (${requestType})`,
      Message: `
        Request: ${requestType}
        Name: ${name} ${furiganaName}
        Company name: ${companyName} ${furiganaCompanyName}
        Email: ${mail}
        Phone: ${phone}
        Subject: ${subject}
        Content: ${feedback}
        `,
    };

    if (file) {
      const promise = upload.append('Support/Ticket:upload', file);
      upload.run();
      promise
        .then((res) => {
          if (res) {
            query.Attach = [res.final];
          }
          return rest('Support/Ticket', 'POST', query);
        })
        .then(() => {
          setSnack(t('shop_contact_sent'), 'success');
          setTimeout(() => {
            history.push(landingPageRoute);
          }, 2000);
        })
        .catch((e: any) => {
          setSnack(e.message, 'error');
        });
      return;
    }

    rest('Support/Ticket', 'POST', query)
      .then(() => {
        setSnack(t('shop_contact_sent'), 'success');
        setTimeout(() => {
          history.push(landingPageRoute);
        }, 2000);
      })
      .catch((e: any) => {
        setSnack(e.message, 'error');
      });
  };

  const radios: RadioOptions[] = [
    { text: t('common_appearance'), value: '出演依頼' },
    { text: t('common_contact_other'), value: 'その他' },
  ];

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['contact'], style['fixed-header']].join(' ') : style['contact'];

  return (
    <div className={classes}>
      <div>
        <h2>{t('shop_title_contact')}</h2>
        <div className={style['text']}>{t('shop_contact_detail')}</div>
        <RadioWithTitle
          title={t('common_appearance')}
          required={true}
          value={requestType}
          onChange={setRequestType}
          radios={radios}
        />
        <InputWithTitle title={t('shop_contact_name')} required={true} value={name} onChange={setName} big={false} />
        <TextField
          placeholder={t('common_furigana')}
          variant={'outlined'}
          fullWidth
          value={furiganaName}
          onChange={(e) => setFuriganaName(e.target.value)}
        />
        <InputWithTitle
          title={t('common_contact_company_name')}
          required={false}
          value={companyName}
          onChange={setCompanyName}
          big={false}
        />
        <TextField
          placeholder={t('common_furigana')}
          variant={'outlined'}
          fullWidth
          value={furiganaCompanyName}
          onChange={(e) => setFuriganaCompanyName(e.target.value)}
        />
        <InputWithTitle
          title={t('shop_contact_mail_address')}
          required={true}
          value={mail}
          onChange={setMail}
          big={false}
        />
        <InputWithTitle
          title={t('common_contact_phone')}
          required={false}
          value={phone}
          onChange={setPhone}
          big={false}
        />
        <InputWithFileUpload title={t('common_upload_file')} required={false} onChange={setFile} />
        <InputWithTitle
          title={t('common_contact_subject')}
          required={true}
          value={subject}
          onChange={setSubject}
          big={false}
        />
        <InputWithTitle
          title={t('common_contact_content')}
          required={true}
          value={feedback}
          onChange={setFeedback}
          big={true}
        />
        <div className={style['btn-container']}>
          <Button onClick={send} className={style['btn']}>
            {t('common_send')}
          </Button>
        </div>
      </div>
    </div>
  );
};
