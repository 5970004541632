import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';
import style from './shop-entry-body.module.scss';
import { Loader } from '../../../components/Loader/Loader';
import missingImg from '../../../assets/images/logo.jpeg';
import { AddToCard } from '../../../components/AddtoCard/AddToCard';
import { Link } from 'react-router-dom';
import { cartPageRoute, shopPageRoute } from '../../../const/routes';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
};

export const ShopEntryBody = ({ id }: Props) => {
  const { t } = useTranslation();
  const [entry, refresh] = useRest<any>(`Catalog/Product/${id}`);
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['shop-entry'], style['fixed-header']].join(' ') : style['shop-entry'];

  if (!entry || !entry.data) {
    return (
      <div className={classes}>
        <div>
          <Loader />
        </div>
      </div>
    );
  }

  const item = entry.data;
  const img = item.Image.main ? item.Image.main[0].Url : missingImg;
  const description = (item['Description.Short'] || item['Description.CatchPhrase'] || '').replaceAll('\n', '<br />');
  return (
    <div className={classes}>
      <div>
        <div className={style['container']}>
          <img src={img} alt={'item_image'} />
          <div className={style['text-container']}>
            <h2>{item['Basic.Name']}</h2>
            <div className={style['price']}>{item.Public_Price.tax.display}</div>
            <div className={style['description']} dangerouslySetInnerHTML={{ __html: description }} />
            <AddToCard className={style['add-to-card']} id={item.Catalog_Product__} />
          </div>
        </div>
        <div className={style['btn-container']}>
          <Link to={shopPageRoute}>{t('cart_back_to_shop')}</Link>
          <Link to={cartPageRoute}>{t('common_view_cart')}</Link>
        </div>
      </div>
    </div>
  );
};
