import React, { useContext } from 'react';
import { RestContextContainer } from '@karpeleslab/klb-react-services';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';
import { useTranslation } from 'react-i18next';
import {
  aboutPageRoute,
  accountPageRoute,
  actPageRoute,
  cartPageRoute,
  checkoutPageRoute,
  contactPageRoute,
  landingPageRoute,
  liveIdPageRoute,
  livesPageRoute,
  loginPageRoute,
  newsIdPageRoute,
  newsPageRoute,
  orderPageRoute,
  orderViewPageRoute,
  policyPageRoute,
  shopEntryPageRoute,
  shopPageRoute,
  thankYouPageRoute,
} from './const/routes';
import { Landing } from './views/landing/Landing';
import { News } from './views/news/News';
import { Lives } from './views/lives/Lives';
import { NewsEntry } from './views/newsEntry/NewsEntry';
import { LiveEntry } from './views/liveEntry/Lives';
import { Shop } from './views/shop/Shop';
import { LoginRequired } from './components/common/LoginRequired';
import { SnackbarContainer } from './contexts/SnackbarContext';
import { UserContainer, UserContext } from './contexts/UserContext';
import { Login } from './views/login/Login';
import { Account } from './views/account/Account';
import { PageLoader } from './views/pageLoader/PageLoader';
import { Cart } from './views/cart/Cart';
import { Checkout } from './views/checkout/Checkout';
import { Order } from './views/order/Order';
import { PageLoaderContainer } from './contexts/PageLoaderContext';
import { ThankYou } from './views/thankyou/ThankYou';
import { Helmet } from 'react-helmet';
import { Contact } from './views/contact/Contact';
import { About } from './views/about/About';
import { Policy } from './views/policy/Policy';
import { Act } from './views/act/Act';
import { ShopEntry } from './views/shopEntry/ShopEntry';

const Page = (): JSX.Element => {
  const [userCtx] = useContext<any>(UserContext);
  const user = !userCtx.user ? null : userCtx.user;

  const location = useLocation();
  return userCtx.loading ? (
    <PageLoader />
  ) : (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path={loginPageRoute}>
          <Login />
        </Route>

        <Route exact path={landingPageRoute}>
          <Landing />
        </Route>

        <Route exact path={newsPageRoute}>
          <News />
        </Route>

        <Route exact path={newsIdPageRoute}>
          <NewsEntry />
        </Route>

        <Route exact path={livesPageRoute}>
          <Lives />
        </Route>

        <Route exact path={liveIdPageRoute}>
          <LiveEntry />
        </Route>

        <Route exact path={shopPageRoute}>
          <Shop />
        </Route>

        <Route exact path={shopEntryPageRoute}>
          <ShopEntry />
        </Route>

        <Route exact path={cartPageRoute}>
          <Cart />
        </Route>

        <Route exact path={contactPageRoute}>
          <Contact />
        </Route>

        <Route exact path={aboutPageRoute}>
          <About />
        </Route>

        <Route exact path={accountPageRoute}>
          <LoginRequired location={location} to={null} user={user}>
            <Account user={user} />
          </LoginRequired>
        </Route>

        <Route exact path={checkoutPageRoute}>
          <LoginRequired location={location} to={null} user={user}>
            <Checkout user={user} />
          </LoginRequired>
        </Route>

        <Route exact path={orderPageRoute}>
          <LoginRequired location={location} to={null} user={user}>
            <Order user={user} />
          </LoginRequired>
        </Route>

        <Route exact path={orderViewPageRoute}>
          <LoginRequired location={location} to={null} user={user}>
            <Order user={user} readonly={true} />
          </LoginRequired>
        </Route>

        <Route exact path={thankYouPageRoute}>
          <ThankYou />
        </Route>

        <Route exact path={policyPageRoute}>
          <Policy />
        </Route>

        <Route exact path={actPageRoute}>
          <Act />
        </Route>

        <Route path="*">
          <Landing />
        </Route>
      </Switch>
    </ThemeProvider>
  );
};

export const Routing = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <RestContextContainer>
      <SnackbarContainer>
        <UserContainer>
          <PageLoaderContainer>
            <Helmet>
              <title>{t('header_title')}</title>
            </Helmet>
            <Page />
          </PageLoaderContainer>
        </UserContainer>
      </SnackbarContainer>
    </RestContextContainer>
  );
};
