import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facebook, Instagram, Twitter } from '@material-ui/icons';
import style from './about-body.module.scss';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

export const AboutBody = () => {
  const { t } = useTranslation();

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['about'], style['fixed-header']].join(' ') : style['about'];

  return (
    <div className={classes}>
      <div>
        <h2>{t('shop_title_about')}</h2>
        <div className={style['text']}>
          <p>HOMEY GOODS WEB SHOP</p>
          <p>
            ラッパー兼作詞家のHOMEYのGOODSのWEB販売。
            <br />
            ライブ会場でGETできるアイテムだけでなく、WEB限定のアイテムもあるので、是非チェックしてください♪
            <br />
            デザインのリクエストも受け付けております。
          </p>
          <p>
            Profile
            <br />
            HOMEY (ホーミー)
          </p>
          <p>
            ラッパー / シンガー
            1988年オーストラリア、ブリズベン生まれ。帰国後中学卒業までを日本で過ごし、アメリカへ。高校生活をアメリカ、カナダで過ごし、帰国後本格的に音楽活動を始める。
          </p>
          <p>
            年間100本近くのライブ活動は都内だけでなく、名古屋や大阪など地方も多く、ライブハウスやホール、更にはストリートなど幅広い。ラップに親しみのない人ですら、聴いている人を幸せな気分にすると定評がある。
          </p>
          <p>
            現在は月に一度、代官山NOMADにて【HOMESTIC】というイベント主催し、１時間のロングセットライブをアコースティックで披露。
            スマートでクールなラップと、甘い歌声はアコースティックという編成を経て、進化し続けている。
          </p>
          <p>
            《作詞》 wind-s.2016年5月３日リリースの最新シングル【Boom Word Up】 / Got7 【Laugh Laugh
            Laugh】オリコン１位獲得。 その他にも、【ジヒョク(超新星) /
            恋の始まりfeat.芹那】の作詞をはじめ、Giselle4のメジャーデビューシングル【Love-A-Dub】やドラマ『アラサーちゃん』のエンディング曲【Wonder
            Girl / Giselle4】、昨年放映された『思いのこし』の挿入歌【SECRET / Giselle4】を全て英詞で担当。
          </p>
        </div>
        <div className={style['sns']}>
          <a
            className={style['fb']}
            href={'https://www.facebook.com/HOMEY.BeTheOne'}
            target={'_blank'}
            rel="noreferrer"
          >
            <Facebook />
          </a>
          <a className={style['twitter']} href={'https://twitter.com/one_homey'} target={'_blank'} rel="noreferrer">
            <Twitter />
          </a>
          <a
            className={style['instagram']}
            href={'https://www.instagram.com/one_homey'}
            target={'_blank'}
            rel="noreferrer"
          >
            <Instagram />
          </a>
        </div>
      </div>
    </div>
  );
};
