import React from 'react';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { Footer } from '../../components/common/Footer';
import { ThankYouBody } from './Body/ThankYouBody';

export const ThankYou = () => {
  return (
    <>
      <Header />
      <ScrollTop />
      <ThankYouBody />
      <Footer />
    </>
  );
};
