import React from 'react';
import { ShopItems } from '../Items/ShopItems';

export const ShopBody = () => {
  return (
    <>
      <ShopItems />
    </>
  );
};
