import { DateTime } from 'luxon';

export const formatLandingDate = (sec: number): string => {
  const dt = DateTime.fromSeconds(sec);
  return dt.toFormat('yyyy.MM.dd');
};

export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
};

const ssr = typeof window !== 'undefined' ? window : undefined;

export const IsWindowScrolledConfig = {
  target: ssr,
  disableHysteresis: true,
  threshold: 1,
};
