import React, { useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import style from './stripe.module.scss';
import { Button } from '@material-ui/core';

export const Stripe = ({ submitForm, loading }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const setSnack = useContext(SnackbarContext);

  const cardOptions = {
    iconStyle: 'solid' as const,
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#737475',
        color: '#737475',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#737475' },
        '::placeholder': { color: '#737475' },
      },
      invalid: {
        iconColor: '#ff0000',
        color: '#ff0000',
      },
    },
  };

  const submitHandler = () => {
    const cardElement = elements?.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    stripe?.createToken(cardElement).then((token) => {
      if (token.token === void 0) {
        setSnack(token.error.message || '', 'error');
      } else {
        submitForm(token);
      }
    });
  };

  return (
    <>
      <div className={style['stripe-container']}>
        <CardElement options={cardOptions} />
      </div>
      <div className={style['stripe-btn-container']}>
        <Button className={style['btn']} onClick={submitHandler} disabled={loading}>
          {t('order_card_btn')}
        </Button>
      </div>
    </>
  );
};
