import React from 'react';
import style from './policy-body.module.scss';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

export const PolicyBody = () => {
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['policy'], style['fixed-header']].join(' ') : style['policy'];

  return (
    <div className={classes}>
      <div>
        <div className={style['text']}>
          <div id="privacy">
            <h2>Privacy Policy</h2>
            <p className="privacy_intro">
              The store（or “we”, “us”, or “our”, as the case may be) recognizes the importance of your personal
              information, and complies with the Act Concerning Protection of Personal Information (hereinafter referred
              to as “Act”) and properly treats and protects your personal information in accordance with this Privacy
              Policy (hereinafter referred to as “Policy”).
              <br />
            </p>
            <dl>
              <dt>1. Definition of personal information</dt>
              <dd>
                As used in this Policy, “personal information” shall mean personal information defined pursuant to
                Article 2.1 of the Act, meaning information pertinent to existing individual, which can personally
                identify a specified individual by its name, birth date, or other description contained in such
                information (including those which can easily collate other information to personally identify a
                specified individual), or those containing personal identification code.
              </dd>
              <dt>2. Purpose of personal information</dt>
              <dd>
                The store shall use your personal information to:{' '}
                <ul>
                  <li>(1) provide our services;</li>
                  <li>(2) give guidance and respond to inquiries in connection with our services;</li>
                  <li>(3) give guidance relating to our goods and services;</li>
                  <li>
                    (4) cope with a violation of our rules and regulations or the terms and conditions, or polices
                    (hereinafter referred to as “Rules”) in connection with our services;
                  </li>
                  <li>(5) notify you of any change in Rules relating to our services;</li>
                  <li>(6) facilitate improvement of our services and development of new services;</li>
                  <li>
                    (7) generate data&nbsp;processed&nbsp;for&nbsp;statistical&nbsp;purposes and made anonymous, which
                    cannot be personally identifiable in connection with our services; and
                  </li>
                  <li>(8) accomplish purposes incidental to purposes as set forth above.</li>
                </ul>
              </dd>
              <dt>3. Change of purposes of use of personal information</dt>
              <dd>
                The store reserves the right to change the purposes of use of personal information for as long as it
                will continue to remain relevant, and notify you of, or make it public such change.
              </dd>
              <dt>4. Restriction on use of personal information</dt>
              <dd>
                Unless otherwise permitted by the Act and other relevant applicable laws and regulations, we shall not
                use your personal information except as required to accomplish the purposes of use without your consent,
                unless:{' '}
                <ul>
                  <li>(1) required by the applicable laws and regulations;</li>
                  <li>
                    (2) it is necessary to protect a person’s life, body, or property, and it is difficult to obtain
                    your consent;
                  </li>
                  <li>
                    (3) it is particularly necessary to improve public health and facilitate sound education for
                    children, and it is difficult to obtain your consent; or
                  </li>
                  <li>
                    (4) it is necessary for us to cooperate with state or local government or its agency in carrying out
                    their statutory duties and functions, and it is detrimental to carry out of them if your consent
                    needs to be obtained.
                  </li>
                </ul>
              </dd>
              <dt>5. Proper collection of personal information</dt>
              <dd>
                We shall collect personal information, and shall not collect personal information by false or otherwise
                illicit means.
              </dd>
              <dt>6. Security of personal information</dt>
              <dd>
                The store shall carry out necessary and proper supervision over its employees, in order to secure your
                personal information against risk of loss, destruction, alteration, and leaking of your personal
                information. If we subcontract, wholly or partially, treatment of your personal information, we shall
                carry out necessary and proper supervision over a subcontractor.
              </dd>
              <dt>7. Provision to third parties</dt>
              <dd>
                Unless otherwise permitted by the Act and other relevant applicable laws and regulations, we shall not
                provide to third parties your personal information without your prior consent, except for cases where:{' '}
                <ul>
                  <li>
                    (1) we provide personal information as a result of subcontracting, wholly or partially, treatment of
                    your personal information to the extent necessary to accomplish the purposes of use of personal
                    information;
                  </li>
                  <li>
                    (2) your personal information is provided by way of merger or amalgamation, or other events of
                    business transfer; and
                  </li>
                  <li>(3) your personal information is shared with third parties in accordance with the Act.</li>
                </ul>
              </dd>
              <dt>8. Disclosure of personal information</dt>
              <dd>
                If we are required by you to disclose your personal information pursuant to the Act, we shall promptly
                disclose it to you, after identifying that such request has been submitted by you (if such information
                does not exist, we shall notify to that effect.), unless otherwise we are not obligated to do so under
                the Act or other relevant laws and regulations.
              </dd>
              <dt>9. Correction of personal information</dt>
              <dd>
                If we are required by you to make a correction, addition, or deletion (hereinafter referred to as
                “Correction”) of your personal information by reason that such information is untrue, we shall promptly
                carry out investigations to the extent necessary to accomplish the purposes of use thereof after
                identifying that such request has been submitted by you, and make a Correction based on the results
                thereof, and you shall be notified thereof (or we shall notify you if we shall not make any Correction),
                unless otherwise we are not obligated to do so under the Act or other relevant laws and regulations.
              </dd>
              <dt>10. Suspension of use of your personal information</dt>
              <dd>
                If we are required by to you to suspend use of or delete (hereinafter referred to as “Suspend”) your
                personal information by reason that such information is treated for any purpose other than those which
                are made public, or is obtained or collected by false or otherwise illicit means, and we found that such
                request is reasonably acceptable, we shall promptly Suspend use of your personal information after
                identifying that such request has been submitted by you, you shall be notified thereof, unless otherwise
                we are not obligated to do so under the Act or other relevant laws and regulations.
              </dd>
              <dt>11. Use of Cookie or other technologies</dt>
              <dd>
                <ul>
                  <li>
                    （１）
                    当ショップのサービスは、Cookie及びこれに類する技術を利用することがあります。これらの技術は、当ショップによる当ショップのサービスの利用状況等の把握に役立ち、サービス向上に資するものです。Cookieを無効化されたいユーザーは、ウェブブラウザの設定を変更することによりCookieを無効化することができます。但し、Cookieを無効化すると、当ショップのサービスの一部の機能をご利用いただけなくなる場合があります。
                  </li>
                  <li>
                    （２）
                    当ショップは、当ショップサービスが提供するサービスの利用状況等を調査・分析するため、本サービス上に
                    Google LCCが提供する Google
                    アナリティクスを利用しています。Googleアナリティクスでデータが収集、処理される仕組みその他Googleアナリティクスの詳しい情報につきましては、同社のサイトをご覧ください。
                    <br />
                    Google アナリティクス 利用規約：
                    <br />
                    <a rel="noreferrer" href="https://www.google.com/analytics/terms/jp.html" target="_blank">
                      https://www.google.com/analytics/terms/jp.html
                    </a>
                    <br />
                    お客様が Google パートナーのサイトやアプリを使用する際の Google によるデータ使用：
                    <br />
                    <a
                      rel="noreferrer"
                      href="https://www.google.com/intl/ja/policies/privacy/partners/"
                      target="_blank"
                    >
                      https://www.google.com/intl/ja/policies/privacy/partners/
                    </a>
                    <br />
                    Google プライバシーポリシー：
                    <br />
                    <a rel="noreferrer" href="http://www.google.com/intl/ja/policies/privacy/" target="_blank">
                      http://www.google.com/intl/ja/policies/privacy/
                    </a>
                    <br />
                    <br />
                    なお、お客様はご自身のデータが Google アナリティクスで使用されることを望まない場合は、Google
                    社の提供する Google アナリティクス オプトアウト アドオンをご利用ください。
                    <br />
                    <br />
                    Google アナリティクス オプトアウト アドオン：
                    <br />
                    <a rel="noreferrer" href="https://tools.google.com/dlpage/gaoptout" target="_blank">
                      https://tools.google.com/dlpage/gaoptout
                    </a>
                  </li>
                  <li>
                    （３） 本サービスでは「Google
                    Analyticsの広告向けの機能」を有効にしており、下記の機能を利用し、広告やサイト改善のためDoubleClick
                    CookieなどのサードパーティCookieを利用しています。
                    <br />
                    <br />
                    Google Analyticsリマーケティング
                    <br />
                    Google Analyticsのユーザー属性レポートとユーザー属性レポートとインタレスト レポート
                    <br />
                    <br />
                    これにより、本サービスではGoogle
                    AnalyticsのCookieを利用して、お客様の年齢・性別・閲覧履歴・本サービスに関する関心の傾向をおおよそ把握するための分析が可能となっております。
                    <br />
                    <br />
                    「Google
                    Analyticsの広告向けの機能」を使用されることを望まない場合は、設定によってトラッキングを無効にすることが可能です。Google
                    Analytics オプトアウト アドオンをブラウザにインストールされると無効にすることができます。
                  </li>
                </ul>
              </dd>
              <dt>12. Inquiries</dt>
              <dd>
                If you have request for disclosure, opinion, question, complaint, or other inquiries about treatment of
                your personal information, please inform us at our Information Desk as specified in “Notation Based on
                the Specified Commercial Transaction Act” herein or make an inquiry by filling up an&nbsp;Inquiry Form
                contained in our store page.
                <br />
              </dd>
              <dt>13. Continuous efforts</dt>
              <dd>
                We shall review the status of operation how your personal information is being treated on a regular
                basis, and use our best efforts to make them better, and modify this Privacy Policy from time to time.
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
