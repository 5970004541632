import React from 'react';
import { Routing } from './Routing';
import * as serviceWorker from './serviceWorker';
import { run } from '@karpeleslab/react-klbfw-hooks';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Backend } from '@karpeleslab/i18next-klb-backend';
import { getLocale, rest } from '@karpeleslab/klbfw';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const i18nOpt: any = {
  // remove any later
  lng: getLocale(),
  initImmediate: false,
  load: 'currentOnly',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
};

(global as any).rest = rest;

run(<Routing />, [i18n.use(Backend).use(initReactI18next).init(i18nOpt)]);

if (typeof window !== 'undefined') {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
