import React from 'react';
import { Redirect } from 'react-router-dom';
import * as H from 'history';
import { loginPageRoute } from '../../const/routes';

type Props = {
  location: H.Location<unknown>;
  user: any | null;
  children: React.ReactElement;
  to: string | null;
};

export const LoginRequired = ({ location, user, children, to }: Props): JSX.Element => {
  if (user) {
    return <>{children}</>;
  }

  const returnTo = encodeURIComponent(location.pathname + location.search + location.hash);

  return <Redirect from={location.pathname} to={to ?? `${loginPageRoute}?return_to=${returnTo}`} />;
};
