import React from 'react';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { LandingMail } from '../../components/Mail/LandingMail';
import { ShopHeader } from './Header/ShopHeader';
import { ShopBody } from './Body/ShopBody';
import style from './shop.module.scss';

export const Shop = () => {
  return (
    <div className={style['shop']}>
      <Header />
      <ScrollTop />
      <ShopHeader />
      <ShopBody />
      <LandingMail />
      <Footer />
    </div>
  );
};
