import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import style from './landing-live.module.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatLandingDate } from '../../../utils';
import { Loader } from '../../../components/Loader/Loader';

export const LandingLive = () => {
  const { t } = useTranslation();
  const [lives, refresh] = useRest<any>('Planner/@planner/Schedule', {
    method: 'GET',
    paging: {
      // Optional, defined the paging of the request
      page_no: 0, // Which page we want
      results_per_page: 5, // How many results per page we want
    },
    Visible: 'Y',
    sort: { Date: 'DESC' },
  });

  const events =
    lives && lives.data ? (
      lives.data.map(({ Date, Label, Planner_Schedule__, Content_Cms_Entry_Data }: any, i: number) => {
        return (
          <div key={i} className={style['row']}>
            <div className={style['date']}>{formatLandingDate(Date.unix)}</div>
            <Link to={`/live/${Content_Cms_Entry_Data.Slug}`} className={style['link']}>
              {Label}
            </Link>
          </div>
        );
      })
    ) : (
      <>
        <Loader />
      </>
    );

  return (
    <div className={style['landing-live']}>
      <div>
        <h3>{t('landing_live_title')}</h3>
        {events}
      </div>
    </div>
  );
};
