import React from 'react';
import { Fab, useScrollTrigger, Zoom } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import style from './scroll-top.module.scss';

export const ScrollTop = () => {
  const ssr = typeof window !== undefined ? window : undefined;
  const trigger = useScrollTrigger({
    target: ssr,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleClick = () => {
    const anchor = document.querySelector('#root');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} className={style['scroll-top']}>
        <Fab className={style['btn']} size="small">
          <KeyboardArrowUp />
        </Fab>
      </div>
    </Zoom>
  );
};
