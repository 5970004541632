import React, { useContext, useEffect, useState } from 'react';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import style from './order-body.module.scss';
import { CartElement } from '../../../components/Cart/CartElement';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '../../../components/Stripe/Stripe';
import { rest } from '@karpeleslab/klbfw';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { PageLoaderContext } from '../../../contexts/PageLoaderContext';
import { thankYouPageRoute } from '../../../const/routes';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

type Props = {
  user: any;
  readonly?: boolean;
};

export const OrderBody = ({ user, readonly }: Props) => {
  const { order } = useParams<{ order: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const setSnack = useContext(SnackbarContext);
  const cartRefresh = useRestRefresh('Catalog/Cart/@');
  const [orderProcess] = useRest<any>(`Order/${order}:process`);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentClass, setPaymentClass] = useState('');
  const orderRefresh = useRestRefresh(`Order/${order}:process`);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const setPageLoader = useContext(PageLoaderContext);

  useEffect(() => {
    setPageLoader(loading);
  }, [loading]);

  const buildStripe = () => {
    if (!orderProcess || !orderProcess.data.methods.Stripe || paymentStatus) {
      return null;
    }

    return (
      <>
        <p>{t('order_payment_credit_terms')}</p>
        {buildStripeElement()}
      </>
    );
  };

  const submitForm = (token: any) => {
    setLoading(true);
    rest(`Order/${orderProcess.data.order.Order__}:process`, 'POST', {
      method: orderProcess.data.methods.Stripe.method,
      session: orderProcess.data.methods.Stripe.session,
      cc_token: token.token.id,
    })
      .then(() => {
        setLoading(false);
        cartRefresh();
        history.push(thankYouPageRoute);
      })
      .catch((data: any) => {
        setLoading(false);
        setSnack(data.message, 'error');
      });
  };

  const buildStripeElement = () => {
    let stripePromise;
    if (!orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account) {
      stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key);
    } else {
      stripePromise = loadStripe(orderProcess.data.methods.Stripe.fields.cc_token.attributes.key, {
        stripeAccount: orderProcess.data.methods.Stripe.fields.cc_token.attributes.options.stripe_account,
      });
    }

    return (
      <Elements stripe={stripePromise}>
        <Stripe submitForm={submitForm} loading={loading} />
      </Elements>
    );
  };

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['order-body'], style['fixed-header']].join(' ') : style['order-body'];

  return (
    <div className={classes}>
      <div>
        <h3>{t('order_title')}</h3>
        <CartElement cart={orderProcess} canRemove={false} />
        {readonly ? (
          <></>
        ) : (
          <>
            <h3>{t('order_payment_credit_title')}</h3>
            {buildStripe()}
          </>
        )}
      </div>
    </div>
  );
};
