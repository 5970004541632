import React from 'react';
import style from './landing-mail.module.scss';
import { useTranslation } from 'react-i18next';
import { Instagram, Twitter, YouTube } from '@material-ui/icons';
import qr from './../../assets/images/qrcode.png';

export const LandingMail = () => {
  const { t } = useTranslation();

  return (
    <div className={style['landing-mail']} id="contact">
      <div>
        <h3>{t('landing_mail_follow_us')}</h3>
        <div className={style['text']}>
          <div>
            <a href={'https://lin.ee/zbxtYzh'}>
              <img src={qr} alt={'qr_code'} />
            </a>
          </div>
          <div className={style['next']}>
            <a target={'_blank'} href={'https://twitter.com/ONE_HOMEY'} rel="noreferrer">
              <Twitter />
            </a>
            <a target={'_blank'} href={'https://www.instagram.com/one_homey/'} rel="noreferrer">
              <Instagram />
            </a>
            <a target={'_blank'} href={'https://www.youtube.com/channel/UCWxNwAr1-PKz66nSYeyabiQ'} rel="noreferrer">
              <YouTube />
            </a>
          </div>
          <div className={style['next']}>
            <iframe
              id="twitter-widget-0"
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              className="twitter-share-button twitter-share-button-rendered twitter-tweet-button"
              style={{ position: 'static', visibility: 'visible', width: '74px', height: '20px' }}
              title="Twitter Tweet Button"
              src="https://platform.twitter.com/widgets/tweet_button.06c6ee58c3810956b7509218508c7b56.ja.html#dnt=false&amp;id=twitter-widget-0&amp;lang=ja&amp;original_referer=https%3A%2F%2Fhomey-web.com%2F%23disco&amp;size=m&amp;text=HOMEY(%E3%83%9B%E3%83%BC%E3%83%9F%E3%83%BC)%20-%20Official%20Website&amp;time=1626649580875&amp;type=share&amp;url=https%3A%2F%2Fhomey-web.com%2F%23disco&amp;via=ONE_HOMEY"
            />
            <iframe
              src="https://www.facebook.com/plugins/like.php?app_id=202261893142593&amp;href=https://homey-web.com/&amp;send=false&amp;layout=button_count&amp;width=100&amp;show_faces=false&amp;action=like&amp;colorscheme=light&amp;font&amp;height=21"
              scrolling="no"
              frameBorder="0"
              style={{ border: 'none', overflow: 'hidden', width: '120px', height: '20px', marginLeft: '10px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
