import React, { createContext, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { Loader } from '../components/Loader/Loader';
import style from './page-loader-context.module.scss';

export const PageLoaderContext = createContext((open: boolean) => {
  //
});

export const PageLoaderContainer = ({ children }: any) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <PageLoaderContext.Provider value={setOpen}>
      <>{children}</>
      <Drawer anchor={'top'} transitionDuration={0} open={open}>
        <div className={style['page-loader-context']}>
          <Loader />
        </div>
      </Drawer>
    </PageLoaderContext.Provider>
  );
};
