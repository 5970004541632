import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#cccccc',
    },
    secondary: {
      main: '#edeef0',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '游ゴシック',
      'Yu Gothic',
      'YuGothic',
      'Hiragino Kaku Gothic ProN',
      'Hiragino Kaku Gothic Pro',
      'メイリオ',
      'Meiryo',
      'ＭＳ ゴシック',
      'sans-serif',
    ].join(','),
  },
});
