export const landingPageRoute = '/';
export const loginPageRoute = '/login';
export const shopPageRoute = '/shop';
export const shopEntryPageRoute = '/shop/:id';
export const aboutPageRoute = '/about';
export const contactPageRoute = '/contact';
export const livesPageRoute = '/lives';
export const liveIdPageRoute = '/live/:slug';
export const newsPageRoute = '/news';
export const newsIdPageRoute = '/news/:slug';
export const bioPageRoute = '/#bio';
export const discoPageRoute = '/#disco';
export const videoPageRoute = '/#video';
export const galleryPageRoute = '/#gallery';
export const accountPageRoute = '/account';
export const cartPageRoute = '/cart';
export const checkoutPageRoute = '/checkout';
export const orderPageRoute = '/order/:order';
export const orderViewPageRoute = '/order/view/:order';
export const thankYouPageRoute = '/thankyou';
export const policyPageRoute = '/policy';
export const actPageRoute = '/act';
