import React from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';

export const Countries = (props: any) => {
  const { valChange, lang, rowItem, item, buildRequired } = props;
  const [countries] = useRest<any>('Country');
  const { t } = useTranslation();

  const buildOption = () => {
    if (countries === null) {
      return false;
    }

    return countries.data.map((item: any, idx: number) => {
      return (
        <option key={idx} value={item.Country__}>
          {item.Name}
        </option>
      );
    });
  };

  return (
    <dl>
      <dt>
        {t(`${rowItem.name}_lbl`)}
        {buildRequired(rowItem.required)}
      </dt>
      <dd>
        <select name={rowItem.name} onChange={valChange} value={lang}>
          {buildOption()}
        </select>
      </dd>
    </dl>
  );
};
