import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '@karpeleslab/klb-react-services';

import style from './oauth2-buttons.module.scss';

export const OAuth2Buttons = (): JSX.Element => {
  const { t } = useTranslation();
  const { oauthFields, onOauthClicked } = useContext(LoginContext);

  const makeButton = () => {
    return oauthFields.map((field) => {
      const colors: { [string: string]: string } = {
        twitter: '#ffffff',
        apple: '#ffffff',
        google: '#000000',
      };
      const cssButton = {
        backgroundColor: field.button['background-color'],
        color: colors[field.info.Name.toLowerCase()] || '#000000',
      };
      const cssIcon = {
        backgroundImage: `url(${field.button['logo']})`,
      };
      return (
        <div
          key={field.id}
          onClick={() => {
            onOauthClicked(field.id);
          }}
          className={style['oauth2-button']}
          style={cssButton}
        >
          <div style={cssIcon} className={style['icon']} />
          <span className={style['label']}>{field.info.Name}</span>
        </div>
      );
    });
  };
  if (!oauthFields || oauthFields.length === 0) {
    return <></>;
  }
  return (
    <div className={style['oauth2-container']}>
      <span>{t('login_social_media')}</span>
      <div className={style['button-list']}>{makeButton()}</div>
    </div>
  );
};
