import React from 'react';
import style from './landing-bio.module.scss';
import { useTranslation } from 'react-i18next';

export const LandingBio = () => {
  const { t } = useTranslation();

  return (
    <div className={style['landing-bio']} id="bio">
      <div>
        <h3>{t('landing_bio_title')}</h3>
        <div className={style['bio']}>
          <h4>HOMEY (ホーミー)</h4>
          <div className={style['text']}>
            <p>オーストラリア生まれのバイリンガルラッパー。</p>
            <p>
              ソロ活動と同時に、３人組Hip Hop
              Group「ラップオバケ」のmemberとしても活動し、ベルウッドレコードより2枚のMiniAlbumを発売。
            </p>
            <p>
              中学時代にHIPHOPに出会い魅了される。 卒業と同時に本場アメリカへの留学を決意。
              高校生活をアメリカ、カナダで過ごし、帰国後本格的に音楽活動を始める。
              作詞作曲も自ら行い、その作品は聞きやすい「メロディックラップ」としてHip
              Hop好きだけではなく、J-POPシーンからも高評価を得ている。
              英語力を生かし英会話講師として芸能人も含めた多数の生徒を持ち、 作詞、英詞、英語発音
              ディレクターとして多数アーティストの作品に参加。 CMなどのナレーション、グッズやロゴのデザインも行う。
            </p>
            <p>
              2019年7月26日に渋谷WWWでのソロワンマンライブを成功させてから、
              アコースティック形態でのライブもスタートさせ、新たなステージへ挑戦している。
            </p>
            <p>&nbsp;</p>
            <p>2021年4月からはInterFM「HIP HOP GREEN」（毎週金曜14:30~16:00)の MCに抜擢される。</p>
            <p>&nbsp;</p>
            <p>
              《作詞》 Sexy Zone / wind-s. / Got7 /ジヒョク(超新星) /Giselle4などに提供
              <br />
              《ナレーション》 J-Leagueの海外向けPromotion CM、風男塾のLIVE用ナレーション等
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
