import React, { useContext, useEffect, useState } from 'react';
import style from './account.module.scss';
import { useOrders, useUserChangePassword, useUserLogout, useUserSetEmail } from '@karpeleslab/klb-react-services';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useScrollTrigger,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { Loader } from '../../../components/Loader/Loader';
import { formatLandingDate, IsWindowScrolledConfig } from '../../../utils';
import { generatePath, Link } from 'react-router-dom';
import { orderViewPageRoute } from '../../../const/routes';
import { UserContext } from '../../../contexts/UserContext';

type Props = {
  user: any;
};

const AccountOrders = ({ user }: Props) => {
  const { t } = useTranslation();
  const [orders, ordersFetch, loading] = useOrders();

  useEffect(() => {
    ordersFetch(
      {
        Invoice_Status: 'done',
      },
      { result_per_page: 20, page_no: 0 }
    );
  }, []);

  let element = (
    <>
      <Loader />
    </>
  );

  if (orders && orders.data) {
    element = (
      <TableContainer component={Paper}>
        <Table className={style['table']} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('account_order_number')}</TableCell>
              <TableCell align="center">{t('account_order_date')}</TableCell>
              <TableCell align="center">{t('account_order_price')}</TableCell>
              <TableCell align="center">{t('account_order_view')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.data.map((order: any, i: number) => (
              <TableRow key={i} className={style['row']}>
                <TableCell component="th" scope="row">
                  {i + 1}
                </TableCell>
                <TableCell align="center">{formatLandingDate(order.Created.unix)}</TableCell>
                <TableCell align="right">{order.Total.display}</TableCell>
                <TableCell align="center">
                  <Link
                    to={generatePath(orderViewPageRoute, {
                      order: order.Order__,
                    })}
                    className={style['btn']}
                  >
                    {t('common_view')}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <div className={style['orders']}>
      <h2>{t('account_order_title')}</h2>
      {element}
    </div>
  );
};

export const AccountBody = ({ user }: Props) => {
  const { t } = useTranslation();

  const setSnack = useContext(SnackbarContext);
  const [, setUser] = useContext(UserContext);

  const [changePwd, pwdLoading] = useUserChangePassword(user ? user.User__ : '');
  const [changeEmail, emailLoading] = useUserSetEmail(user ? user.User__ : '');

  const hasPassword = user ? user.Has_Password : false;
  const currentEmail = user ? user.Email : '';

  const [email, setEmail] = useState(currentEmail);
  const [currentPwd, setCurrentPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');

  const [logout] = useUserLogout();

  const onLogout = () => {
    logout()
      .then(() => {
        setUser({
          loading: false,
          loggedIn: false,
          user: null,
        });
      })
      .catch((e: any) => console.error(e));
  };

  const onSave = () => {
    const promises = [];

    if (newPwd !== '' && confirmPwd !== '') {
      if (hasPassword && currentPwd === '') {
        setSnack(t('account_current_password_required'), 'error');
        return;
      }
      if (newPwd !== confirmPwd) {
        setSnack(t('account_password_different'), 'error');
        return;
      }

      if (currentPwd === newPwd) {
        setSnack(t('account_password_equals_current'), 'error');
        return;
      }

      promises.push(changePwd(hasPassword ? currentPwd : null, newPwd));
    }

    if (currentEmail !== email) {
      if (!hasPassword) {
        promises.push(changeEmail(email, null));
      } else {
        if (currentPwd === '') {
          setSnack(t('account_current_password_required'), 'error');
          return;
        }
        promises.push(changeEmail(email, currentPwd));
      }
    }

    if (promises.length === 0) {
      return;
    }

    Promise.all(promises).then(() => {
      setSnack(t('account_info_update_success'), 'success');
      setTimeout(() => {
        history.go(0);
      }, 2000);
    });
  };

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['account'], style['fixed-header']].join(' ') : style['account'];

  return (
    <div className={classes}>
      <div>
        <h2>{t('account_title')}</h2>
        <div className={style['container']}>
          <div>
            <h4>{t('account_email_title')}</h4>
            <TextField
              placeholder={t('common_email')}
              fullWidth
              value={email}
              required
              onChange={(event) => setEmail(event.target.value)}
            />
            {hasPassword ? <div className={style['email-info']}>{t('account_email_password')}</div> : <></>}
          </div>
          <div className={style['password-container']}>
            <h4>{t('account_change_password')} </h4>
            <TextField
              placeholder={t('account_current_password')}
              type={'password'}
              fullWidth
              value={currentPwd}
              onChange={(event) => setCurrentPwd(event.target.value)}
              className={style['password-input']}
            />
            <TextField
              placeholder={t('account_new_password')}
              type={'password'}
              fullWidth
              value={newPwd}
              onChange={(event) => setNewPwd(event.target.value)}
              className={style['password-input']}
            />
            <TextField
              placeholder={t('account_confirm_password')}
              type={'password'}
              fullWidth
              value={confirmPwd}
              onChange={(event) => setConfirmPwd(event.target.value)}
              className={style['password-input']}
            />
          </div>
          <div>
            <Button className={style['btn']} onClick={onSave} disabled={pwdLoading || emailLoading}>
              {t('common_save')}
            </Button>
          </div>
          <div>
            <h4>{t('account_logout')}</h4>
            <Button className={style['btn']} onClick={onLogout}>
              {t('account_logout')}
            </Button>
          </div>
        </div>
      </div>
      <AccountOrders user={user} />
    </div>
  );
};
