import React from 'react';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { Carousel } from '../../components/carousel/Carousel';
import { LandingLive } from './Live/Live';
import { LandingNews } from './News/News';
import { LandingBio } from './Bio/Bio';
import { LandingDisco } from './Disco/Disco';
import { LandingVideo } from './Video/LandingVideo';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { LandingMail } from '../../components/Mail/LandingMail';
import { useMediaQuery } from '@material-ui/core';

export const Landing = () => {
  return (
    <>
      <Header />
      <Carousel />
      <LandingLive />
      <LandingNews />
      <LandingBio />
      <LandingDisco />
      <LandingVideo />
      <LandingMail />
      <ScrollTop />
      <Footer />
    </>
  );
};
