import React from 'react';
import { Carousel as CarouselElement } from 'react-responsive-carousel';
import car2 from './../../assets/images/carousel-2.jpeg';
import car2Mobile from './../../assets/images/carousel-2-1000.jpeg';
import car3 from './../../assets/images/carousel-3.jpeg';
import car3Mobile from './../../assets/images/carousel-3-1000.jpeg';
import car4 from './../../assets/images/carousel-4.jpg';
import car4Mobile from './../../assets/images/carousel-4-1000.jpg';
import car5 from './../../assets/images/carousel-5.jpg';
import car5Mobile from './../../assets/images/carousel-5-1000.jpg';
import { useMediaQuery, useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../utils';
import style from './carousel.module.scss';

export const Carousel = () => {
  const matches = useMediaQuery('(max-width:1000px)');
  const img1 = matches ? car2Mobile : car2;
  const img2 = matches ? car3Mobile : car3;
  const img3 = matches ? car4Mobile : car4;
  const img4 = matches ? car5Mobile : car5;

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);

  return (
    <CarouselElement
      autoPlay={true}
      interval={3000}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      dynamicHeight={true}
      className={scrollTrigger ? style['carousel'] : ''}
    >
      <div>
        <img src={img4} />
      </div>
      <div>
        <img src={img3} />
      </div>
      <div>
        <img src={img2} />
      </div>
      <div>
        <img src={img1} />
      </div>
    </CarouselElement>
  );
};
