import React from 'react';
import './footer.module.scss';
import { Link } from 'react-router-dom';
import { aboutPageRoute, actPageRoute, contactPageRoute, policyPageRoute } from '../../const/routes';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <div>© HOMEY All Rights Reserved.</div>
        <Link to={contactPageRoute}>{t('header_menu_contact')}</Link>
        <Link to={aboutPageRoute}>{t('header_menu_about')}</Link>
        <Link to={policyPageRoute}>{t('header_menu_policy')}</Link>
        <Link to={actPageRoute}>{t('header_menu_act')}</Link>
      </footer>
    </>
  );
};
