import React from 'react';
import style from './shop-header.module.scss';
import logo from './../../../assets/images/shop.jpeg';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

export const ShopHeader = () => {
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['shop-header'], style['fixed-header']].join(' ') : style['shop-header'];

  return (
    <>
      <div className={classes}>
        <div>
          <img src={logo} alt={'Logo'} />
        </div>
      </div>
    </>
  );
};
