import React, { useContext, useState } from 'react';
import style from './cart-body.module.scss';
import { useTranslation } from 'react-i18next';
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { Loader } from '../../../components/Loader/Loader';
import { TextField, Button, useScrollTrigger } from '@material-ui/core';
import { rest } from '@karpeleslab/klbfw';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { checkoutPageRoute, shopPageRoute } from '../../../const/routes';
import { Link } from 'react-router-dom';
import { CartElement } from '../../../components/Cart/CartElement';
import { IsWindowScrolledConfig } from '../../../utils';

export const CartBody = () => {
  const { t } = useTranslation();
  const setSnack = useContext(SnackbarContext);
  const cartRefresh = useRestRefresh('Catalog/Cart/@');

  const [cart] = useRest<any>('Catalog/Cart/@');

  const [coupon, setCoupon] = useState('');

  const addCoupon = () => {
    if (coupon.trim().length === 0) {
      return;
    }

    const code = `coupon,coupon-code=${encodeURIComponent(coupon)}`;
    const query = {
      request: code,
    };

    rest('Catalog/Cart/@:process', 'POST', query)
      .then(() => {
        cartRefresh();
        setSnack(t('toast_coupon_added'), 'success');
        setCoupon('');
      })
      .catch(() => {
        setSnack(t('toast_coupon_error'), 'error');
      });
  };

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['cart-body'], style['fixed-header']].join(' ') : style['cart-body'];

  return (
    <div className={classes}>
      <div>
        <h3>{t('cart_title')}</h3>
        {!cart ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <CartElement cart={cart} canRemove={true} />
            {cart.data.products.length > 0 ? (
              <>
                <h4>{t('cart_coupon_title')}</h4>
                <div className={style['coupon-container']}>
                  <TextField
                    placeholder={t('cart_coupon_placeholder')}
                    variant="outlined"
                    className={style['input']}
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <Button size={'large'} className={style['btn']} onClick={addCoupon}>
                    {t('common_add_basket')}
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className={style['btn-container']}>
              <Link to={shopPageRoute}>{t('cart_back_to_shop')}</Link>
              {cart.data.products.length > 0 ? <Link to={checkoutPageRoute}>{t('cart_process_payment')}</Link> : <></>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
