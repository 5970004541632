import React from 'react';
import style from './thankyou.module.scss';
import img1 from './../../../assets/images/logohd.jpeg';
import { Link } from 'react-router-dom';
import { landingPageRoute } from '../../../const/routes';
import { useTranslation } from 'react-i18next';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../../utils';

export const ThankYouBody = () => {
  const { t } = useTranslation();
  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['ty-body'], style['fixed-header']].join(' ') : style['ty-body'];

  return (
    <div className={classes}>
      <div>
        <img src={img1} alt={'img1'} />
        <h1>{t('common_thankyou')}</h1>
        <Link className={style['btn']} to={landingPageRoute}>
          {t('common_home')}
        </Link>
      </div>
    </div>
  );
};
