import React, { useEffect } from 'react';
import { Redirect as ReactRedirect } from 'react-router-dom';
import { trimPrefix } from '@karpeleslab/klbfw';
import CircularProgress from '@material-ui/core/CircularProgress';

type Props = {
  target: string;
};

export const Redirect = ({ target }: Props): JSX.Element => {
  const external = target.startsWith('http');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    if (external) {
      window.location.replace(target);
    }
  }, []);

  if (!external) {
    const [, newTarget] = trimPrefix(target);
    return <ReactRedirect to={newTarget} />;
  }

  return <CircularProgress />;
};

export default Redirect;
