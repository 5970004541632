import React, { useContext, useState } from 'react';
import style from './add-to-card.module.scss';
import { Button, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { rest } from '@karpeleslab/klbfw';
import { useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { SnackbarContext } from '../../contexts/SnackbarContext';

type Props = {
  id: string;
  className?: string;
};

export const AddToCard = ({ id, className }: Props) => {
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState('1');
  const cartRefresh = useRestRefresh('Catalog/Cart/@');
  const setSnack = useContext(SnackbarContext);

  const addToCart = (productId: string) => {
    if (quantity === '') {
      return;
    }

    const query = {
      product: productId,
      quantity,
    };

    rest('Catalog/Cart/@:add', 'POST', query)
      .then(() => {
        cartRefresh();
        setSnack(t('toast_coupon_added'), 'success');
      })
      .catch(() => {
        cartRefresh();
        setSnack(t('toast_coupon_error'), 'error');
      });
  };

  return (
    <>
      <Select
        native
        className={`${style['amount']} ${className}`}
        placeholder={t('shop_input_amount')}
        value={quantity}
        onChange={(e) => setQuantity(e.target.value as string)}
        inputProps={{
          name: t('shop_input_amount'),
        }}
      >
        {[...Array(6).keys()].map((nb) => {
          return (
            <option value={nb + 1} key={`${id}_${nb + 1}`}>
              {nb + 1}
            </option>
          );
        })}
      </Select>
      <div className={style['btn']}>
        <Button variant="contained" onClick={() => addToCart(id)}>
          {t('shop_add_to_card')}
        </Button>
      </div>
    </>
  );
};
