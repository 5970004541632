import React, { useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '@karpeleslab/klb-react-services';
import { LoginFormBuilder } from '../login-form-builder/LoginFormBuilder';
import { OAuth2Buttons } from '../oauth2-buttons/OAuth2Buttons';
import { PageLoaderContext } from '../../../contexts/PageLoaderContext';

import style from './login-box.module.scss';

export const LoginBox = (): JSX.Element => {
  const { t } = useTranslation();
  const { loading, handleSubmit } = useContext(LoginContext);

  const setLoading = useContext(PageLoaderContext);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className={style['login-box']}>
      <>
        <form onSubmit={handleSubmit}>
          <LoginFormBuilder />
        </form>
        <div className={style['submit-container']}>
          <Button className={style['submit']} onClick={handleSubmit}>
            {t('login_button')}
          </Button>
        </div>
        <OAuth2Buttons />
      </>
    </div>
  );
};
