import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import {
  accountPageRoute,
  bioPageRoute,
  cartPageRoute,
  contactPageRoute,
  discoPageRoute,
  landingPageRoute,
  livesPageRoute,
  newsPageRoute,
  shopPageRoute,
  videoPageRoute,
} from '../../const/routes';
import { Badge, Button, Drawer, IconButton, List, ListItem, useMediaQuery, useScrollTrigger } from '@material-ui/core';
import { ArrowForwardIos, ShoppingCart, Menu, Close } from '@material-ui/icons';
import style from './header.module.scss';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { IsWindowScrolledConfig } from '../../utils';

type Props = {
  hideContact?: boolean;
  hideShop?: boolean;
};

export const Header = ({ hideContact, hideShop }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const [cart] = useRest<any>('Catalog/Cart/@');
  const [cartAmount, setCartAmount] = useState(0);
  const matches = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (cart && cart.data) {
      const amount = cart.data.products.reduce((nb: number, product: any) => {
        return nb + product.quantity;
      }, 0);
      setCartAmount(amount);
    }
  }, [cart]);

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);

  const redirect = (path: string) => {
    history.push(path);
  };
  const openMenu = () => {
    setOpen(!open);
  };

  const menuList = [
    {
      translate: 'header_menu_live',
      path: livesPageRoute,
      isAnchor: false,
      isOnlyMobile: false,
    },
    {
      translate: 'header_menu_news',
      path: newsPageRoute,
      isAnchor: false,
      isOnlyMobile: false,
    },
    {
      translate: 'header_menu_bio',
      path: bioPageRoute,
      isAnchor: true,
      isOnlyMobile: false,
    },
    {
      translate: 'header_menu_disco',
      path: discoPageRoute,
      isAnchor: true,
      isOnlyMobile: false,
    },
    {
      translate: 'header_menu_videos',
      path: videoPageRoute,
      isAnchor: true,
      isOnlyMobile: false,
    },
    {
      translate: 'header_menu_instagram',
      path: 'https://www.instagram.com/one_homey/',
      isAnchor: false,
      isOnlyMobile: false,
    },
    {
      translate: 'header_account',
      path: accountPageRoute,
      isAnchor: false,
      isOnlyMobile: true,
    },
    {
      translate: 'header_menu_contact',
      path: contactPageRoute,
      isAnchor: false,
      isOnlyMobile: false,
    },
  ];

  const menu = (
    <div className={style['menu-list']} onClick={() => setOpen(false)} onKeyUp={() => setOpen(false)}>
      <IconButton className={style['close-item']}>
        <Close />
      </IconButton>
      <List>
        {menuList.map(({ translate, path, isAnchor, isOnlyMobile }, i) => {
          let classes;
          if (isOnlyMobile) {
            classes = [style['item'], matches ? '' : style['hidden']].join(' ');
          } else {
            classes = style['item'];
          }

          return (
            <ListItem key={i} button className={classes}>
              {isAnchor ? (
                <HashLink className={style['item-text']} to={path}>
                  {t(translate)}
                </HashLink>
              ) : (
                <Link className={style['item-text']} to={path}>
                  {t(translate)}
                </Link>
              )}
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const display = !open ? (scrollTrigger ? 'fixed' : 'block') : 'none';

  return (
    <>
      <header style={{ display }} className={scrollTrigger ? style['sticky'] : ''}>
        <div>
          <div>
            <Link className={style['name']} to={landingPageRoute}>
              {t('header_title')}
            </Link>
          </div>
          <div className={style['buttons']}>
            {hideContact ? (
              <></>
            ) : (
              <Link to={contactPageRoute} className={style['button']}>
                <ArrowForwardIos className={style['icon-size']} />
                {t('header_contact')}
              </Link>
            )}

            <Link to={accountPageRoute} className={style['button']}>
              {t('header_account')}
            </Link>
            <IconButton onClick={() => redirect(cartPageRoute)}>
              <Badge badgeContent={cartAmount} color="secondary">
                <ShoppingCart />
              </Badge>
            </IconButton>
            <Button onClick={openMenu}>
              <Menu />
            </Button>
            <Drawer anchor={'top'} open={open}>
              {menu}
            </Drawer>
          </div>
        </div>
      </header>
    </>
  );
};
