import { Loader } from '../../components/Loader/Loader';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './page-loader.module.scss';

export const PageLoader = () => {
  const { t } = useTranslation();

  return (
    <div className={style['page-loader']}>
      <Loader message={t('loading')} />
    </div>
  );
};
