import React from 'react';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { useParams } from 'react-router';
import { ShopEntryBody } from './Body/ShopEntryBody';

export const ShopEntry = () => {
  const { id } = useParams<any>();

  return (
    <>
      <Header />
      <ScrollTop />
      <ShopEntryBody id={id} />
      <Footer />
    </>
  );
};
