import React, { useContext } from 'react';
import { LoginContextContainer } from '@karpeleslab/klb-react-services';

import style from './login.module.scss';
import { useTranslation } from 'react-i18next';
import { LoginBox } from './login-box/LoginBox';
import { UserContext } from '../../contexts/UserContext';
import { landingPageRoute } from '../../const/routes';
import Redirect from '../../components/common/Redirect';
import { Header } from '../../components/common/Header';
import { Footer } from '../../components/common/Footer';
import { getPrefix } from '@karpeleslab/klbfw';
import { useHistory } from 'react-router-dom';
import { PageLoaderContext } from '../../contexts/PageLoaderContext';
import { useScrollTrigger } from '@material-ui/core';
import { IsWindowScrolledConfig } from '../../utils';

export const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();

  const [user, setUser] = useContext(UserContext);
  const setLoading = useContext(PageLoaderContext);

  const scrollTrigger = useScrollTrigger(IsWindowScrolledConfig);
  const classes = scrollTrigger ? [style['login'], style['fixed-header']].join(' ') : style['login'];

  const onValidated = (data: any) => {
    setUser({
      loggedIn: true,
      loading: false,
      user: data.user,
    });
    setLoading(false);
    history.push(data.Redirect.replace(getPrefix(), ''));
  };

  if (user.loggedIn) {
    return <Redirect target={landingPageRoute} />;
  }

  return (
    <LoginContextContainer onValidated={onValidated}>
      <Header />
      <div className={classes}>
        <h2 className={style['title']}>{t('login_title')}</h2>
        <LoginBox />
      </div>
      <Footer />
    </LoginContextContainer>
  );
};
