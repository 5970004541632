import React, { createContext, useEffect, useState } from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';

export type UserContextType = {
  loggedIn: boolean;
  loading: boolean;
  user: any | null;
};

export const defaultUserContext: UserContextType = {
  loggedIn: false,
  loading: true,
  user: null,
};

export const UserContext = createContext<UserContextFullType>([
  defaultUserContext,
  () => {
    /*d */
  },
]);

type Props = {
  children: JSX.Element;
};

type UserContextFullType = [
  UserContextType,
  (value: ((prevState: UserContextType) => UserContextType) | UserContextType) => void
];

export const UserContainer = ({ children }: Props) => {
  const [user, setUser] = useState<UserContextType>(defaultUserContext);

  const [loggedUser] = useRest<any>('User/@');

  useEffect(() => {
    if (!loggedUser) {
      return;
    }

    const userCtx =
      loggedUser.result === 'success'
        ? { loggedIn: true, loading: false, user: loggedUser.data }
        : ({} as UserContextType);

    setUser({ ...userCtx, loading: false });
  }, [loggedUser]);

  return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};
