import React from 'react';
import { Header } from '../../components/common/Header';
import { ScrollTop } from '../../components/ScrollTop/ScrollTop';
import { Footer } from '../../components/common/Footer';
import { PolicyBody } from './Body/PolicyBody';

export const Policy = () => {
  return (
    <>
      <Header />
      <ScrollTop />
      <PolicyBody />
      <Footer />
    </>
  );
};
