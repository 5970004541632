import React, { useContext, useEffect } from 'react';
import { Checkbox, FormControlLabel, Link, TextField, Typography } from '@material-ui/core';
import { LoginContext } from '@karpeleslab/klb-react-services';

import style from './login-form-builder.module.scss';

export const LoginFormBuilder = (): JSX.Element => {
  const { fields, onInputChange } = useContext(LoginContext);

  useEffect(() => {
    //
  }, [fields]);

  const elements = fields
    .filter((field) => field.type !== 'oauth2')
    .map((field, i) => {
      const fieldId = (field.name ?? 0) + i;
      switch (field.type) {
        case 'text':
        case 'password':
        case 'email':
          return (
            <TextField
              margin="normal"
              required={field.required}
              fullWidth
              name={field.name}
              label={field.label}
              type={field.type}
              id={fieldId}
              key={fieldId}
              onChange={onInputChange}
            />
          );
        case 'label':
          if (field.link) {
            return (
              <Link href={field.link} key={fieldId}>
                {field.label}
              </Link>
            );
          }

          // only snackbar on mobile (https://git.atonline.com/shells/shells-cp/-/issues/31)
          if (field.style === 'error' /*&& isMobile*/) {
            return (
              <div key={fieldId} className={style['error']}>
                {field.label}
              </div>
            );
          }

          return (
            <Typography
              className={style['label']}
              variant="body2"
              key={fieldId}
              paragraph
              color={field.style === 'error' ? 'secondary' : 'initial'}
            >
              {field.label}
            </Typography>
          );
        case 'checkbox': {
          const checkbox = (
            <Checkbox
              value="remember"
              color="primary"
              name={field.name}
              required={field.required}
              id={fieldId}
              onChange={onInputChange}
            />
          );

          return <FormControlLabel key={fieldId} control={checkbox} label={field.label} />;
        }
        default:
          return <div key={fieldId} />;
      }
    });

  return <div className={style['login-form-builder']}>{elements}</div>;
};
